<template>
  <v-card class="p-4">
    <section class="d-flex flex-column flex-sm-row justify-content-between">
      <h4 class="ml-1 mb-4 font-weight-bold">
        Smartsheet
      </h4>
      <v-btn v-if="rolModuloPermiso && rolModuloPermiso.escritura" color="blue darken-3" @click="openModalAddDashboard" :dark="false" small>
        <span class="text-white">
          Agregar Dashboard
        </span>
      </v-btn>
    </section>
    <v-row align-content="center" align-md="center" class="mt-3">
      <v-col cols="12" md="6" sm="12" class="py-1">
        <label for="name_document">
          Listado de Dashboards
        </label>
        <v-autocomplete
          v-model="form.dashboard"
          :items="dashboards"
          @change="setCurrentDashboard"
          clearable
          item-text="name"
          item-value="id"
          searchable
          :loading="loadingDashboards"
          no-data-text="Sin dashboards agregados"
          label="Todo los dashboards"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" sm="12" class="pt-0" v-if="currentDashboard">
        <div class="text-right">
          <v-btn
            small
            class="mt-2"
            color="red darken-3"
            :loading="loading"
            v-if="rolModuloPermiso && rolModuloPermiso.escritura"
            @click="deleteItem(currentDashboard)"
          >
            <small class="text-white">
              Eliminar Dashboard
            </small>
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12" md="12" sm="12" v-if="loadingChangeDashboard">
        <section class="text-center mt-5">
          <v-progress-circular
            class="mt-5"
            color="primary"
            indeterminate
            size="64"
          ></v-progress-circular>
          <p class="mt-2">Cargando..</p>
        </section>
      </v-col>
      <v-col
        cols="12"
        md="12"
        sm="12"
        v-if="currentDashboard"
        v-show="!loadingChangeDashboard"
      >
        <v-row align-content="center" align-md="center" class="mt-3">
          <v-col cols="12" md="6" sm="12" class="pt-0 pb-1">
            <v-card-subtitle class="py-0">
              Nombre
            </v-card-subtitle>
            <v-card-text class="text--primary">
              <div>{{ currentDashboard.name }}</div>
            </v-card-text>
          </v-col>
          <v-col cols="12" md="6" sm="12" class="pt-0 pb-1">
            <v-card-subtitle class="py-0">
              Descripción
            </v-card-subtitle>
            <v-card-text class="text--primary">
              <div>{{ currentDashboard.description }}</div>
            </v-card-text>
          </v-col>
        </v-row>
        <iframe
          class="w-100"
          height="1000"
          frameborder="0"
          @load="offLoadingDashboard"
          :src="currentDashboard.url"
        >
        </iframe>
      </v-col>
    </v-row>
    <add-dashboard
      @dashboardAdded="getDashboardsData()"
      :modalAction="dialogAddDashboard"
      :closeModalAddDashboard="closeModalAddDashboard"
    ></add-dashboard>
  </v-card>
</template>

<script>
import { proyectoMethods, proyectoComputed } from '@/state/helpers'
import { authMethods, authUsuarioComputed } from '@/state/helpers'
import { permiso } from '@/helpers/authservice/obtener-permisos'
import { mapActions, mapGetters } from 'vuex'
import { preventCloseIfNotKeyEventEsc } from '@/helpers/common'
import AddDashboard from './components/AddDashboard.vue'
import swal2 from 'sweetalert2'
export default {
  components: { AddDashboard },
  data() {
    return {
      dialogAddDashboard: false,
      currentDashboard: null,
      loading: false,
      loadingChangeDashboard: false,
      form: {
        dashboard: null
      },
      //Data a mover a mixin
      projectSelected: {},
      codeProject: null,
      codePlace: null,
      lugarInstalacion: {},
      rolModuloPermiso: {}
    }
  },
  async mounted() {
    await this.obtenerProyectoUsuario()
    this.codeProject = this.$route.params.codigoProyecto
    this.codePlace = this.$route.params.codigoLugarInstalacion
    this.lugarInstalacion = {
      codigo_lugar_instalacion: this.codePlace,
      codigo_proyecto: this.codeProject
    }
    let permisos = permiso.obtenerPermisos(this.user, this.$router.currentRoute.name)
    this.rolModuloPermiso = permisos
    this.getDashboardsData()
  },
  methods: {
    ...proyectoMethods,
    ...authMethods,
    ...mapActions({
      getDashboards: 'smartsheet/getDashboards',
      deleteDashboard: 'smartsheet/deleteDashboard'
    }),
    offLoadingDashboard() {
      this.loadingChangeDashboard = false
    },
    setCurrentDashboard() {
      this.loadingChangeDashboard = true
      this.currentDashboard = this.form.dashboard
        ? this.dashboards.find((item) => item.id === this.form.dashboard)
        : null
    },
    obtenerProyectoUsuario() {
      this.obtenerProyecto(this.$route.params.codigoProyecto)
        .then((res) => {
          this.projectSelected = res.body
        })
        .catch((error) => {})
    },
    async getDashboardsData() {
      await this.getDashboards({
        place_code: this.codePlace
      })
    },
    openModalAddDashboard() {
      this.dialogAddDashboard = true
    },
    closeModalAddDashboard($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event)
      if (validationEvent) {
        this.dialogAddDashboard = false
      }
    },
    deleteItem({ id, name }) {
      let self = this
      this.$swal
        .fire({
          icon: 'error',
          title: `Deseas eliminar este dashboard: ${name} ?`,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: 'Eliminar',
          cancelButtonText: `Cancelar`
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            self.loading = true
            const resp = await this.deleteDashboard({
              smartsheet_id: id,
              user: self.user.email
            })
            if (resp.status === 200) {
              swal2.fire({
                icon: 'success',
                title: 'Excelente',
                text: 'Dashboard eliminado correctamente',
                position: 'top-end',
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true
              })
              self.currentDashboard = null
              self.getDashboardsData()
            }
            self.loading = false
          }
        })
    }
  },
  computed: {
    ...proyectoComputed,
    ...authUsuarioComputed,
    ...mapGetters({
      dashboards: 'smartsheet/dashboards',
      loadingDashboards: 'smartsheet/loading'
    })
  }
}
</script>

<style></style>
